<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="block"
        :products-connection="block.typeHandle === 'productGrid' && collection ? collection.products : null"
        :grid-blocks="gridBlocks"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ProductListingFeature, {
  ShopBlocksProductListingFeatureFragment,
} from '@/components/shop/shopBlocks/ProductListingFeature/ProductListingFeature';
import AsSeenIn, { ShopBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import ImageTitleFeature, { ShopBlocksImageTitleFeatureFragment } from '@/components/common/blocks/ImageTitleFeature';
import ProductSlider, { ShopBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import LargeImageFeature, { ShopBlocksLargeImageFeatureFragment } from '@/components/shop/shopBlocks/LargeImageFeature';
import CollectionsSlider, { ShopBlocksCollectionsSliderFragment } from '@/components/common/blocks/CollectionsSlider';
import FullWidthTeaserBox from '@/components/media/FullWidthTeaserBox/FullWidthTeaserBox';
import { ShopBlocksFullWidthTeaserBoxFragment } from '@/components/media/FullWidthTeaserBox/FullWidthTeaserBox.gql';
import ProductFeatureThree, {
  ShopBlocksProductFeatureThreeFragment,
} from '@/components/shop/shopBlocks/ProductFeatureThree/ProductFeatureThree';
import SmallTopicFeature, { ShopBlocksSmallTopicFeatureFragment } from '@/components/shop/shopBlocks/SmallTopicFeature';
import AppCta, { ShopBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import ProductGrid, { ShopBlocksProductGridFragment } from '@/components/shop/shopBlocks/ProductGrid/ProductGrid';
import ExerciseRoutineCardsSliderWithText, {
  ShopBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import ContentRecommendationSlide, {
  ShopBlocksContentRecommendationSlideFragment,
} from '@/components/common/blocks/ContentRecommendationSlide';
import CollectionTitle, {
  ShopBlocksCollectionTitleFragment,
} from '@/components/shop/shopBlocks/CollectionTitle/CollectionTitle';
import CollectionInformation, {
  ShopBlocksCollectionInformationFragment,
} from '@/components/shop/shopBlocks/CollectionInformation/CollectionInformation';
import CenteredContent from '@/components/content/CenteredContent/CenteredContent';
import { ShopBlocksCenteredContentFragment } from '@/components/content/CenteredContent/CenteredContent.gql';
import ImageText from '@/components/media/MediaText/MediaText';
import { ShopBlocksImageTextFragment } from '@/components/media/MediaText/MediaText.gql';
import CompareContentSlider, {
  ShopBlocksCompareContentSliderFragment,
} from '@/components/shop/shopBlocks/CompareContentSlider/CompareContentSlider';
import CompareTable, { ShopBlocksCompareTableFragment } from '~/components/shop/shopBlocks/CompareTable/CompareTable';
import GenericIframe from '@/components/content/GenericIframe/GenericIframe';
import { ShopBlocksGenericIframeFragment } from '@/components/content/GenericIframe/GenericIframe.gql';
import ImageOrVideo from '@/components/media/ImageOrVideo/ImageOrVideo';
import { ShopBlocksImageOrVideoFragment } from '@/components/media/ImageOrVideo/ImageOrVideo.gql';
import ShopBlocksNewsletterSignupFormFragment from '@/components/forms/NewsletterSignupForm/ShopBlocksNewsletterSignupFormFragment.gql';
import NewsletterSignupForm from '@/components/forms/NewsletterSignupForm';
import { RoutinePreviewFragment } from '@/components/common/RoutinePreviewFragment.gql';

export const ShopBlocksMatrixFragment = gql`
  fragment ShopBlocksMatrixFragment on shopBlocks_MatrixField {
    ...ShopBlocksProductListingFeatureFragment
    ...ShopBlocksAsSeenInFragment
    ...ShopBlocksImageTitleFeatureFragment
    ...ShopBlocksProductSliderFragment
    ...ShopBlocksLargeImageFeatureFragment
    ...ShopBlocksCollectionsSliderFragment
    ...ShopBlocksFullWidthTeaserBoxFragment
    ...ShopBlocksProductFeatureThreeFragment
    ...ShopBlocksSmallTopicFeatureFragment
    ...ShopBlocksAppCtaFragment
    ...ShopBlocksProductGridFragment
    ...ShopBlocksRoutineCardsSliderWithTextFragment
    ...ShopBlocksContentRecommendationSlideFragment
    ...ShopBlocksCollectionTitleFragment
    ...ShopBlocksCollectionInformationFragment
    ...ShopBlocksCenteredContentFragment
    ...ShopBlocksImageTextFragment
    ...ShopBlocksCompareContentSliderFragment
    ...ShopBlocksCompareTableFragment
    ...ShopBlocksGenericIframeFragment
    ...ShopBlocksImageOrVideoFragment
    ...ShopBlocksNewsletterSignupFormFragment
  }
  ${ShopBlocksProductListingFeatureFragment}
  ${ShopBlocksAsSeenInFragment}
  ${ShopBlocksImageTitleFeatureFragment}
  ${ShopBlocksProductSliderFragment}
  ${ShopBlocksLargeImageFeatureFragment}
  ${ShopBlocksCollectionsSliderFragment}
  ${ShopBlocksFullWidthTeaserBoxFragment}
  ${ShopBlocksProductFeatureThreeFragment}
  ${ShopBlocksSmallTopicFeatureFragment}
  ${ShopBlocksAppCtaFragment}
  ${ShopBlocksProductGridFragment}
  ${ShopBlocksRoutineCardsSliderWithTextFragment}
  ${ShopBlocksContentRecommendationSlideFragment}
  ${ShopBlocksCollectionTitleFragment}
  ${ShopBlocksCollectionInformationFragment}
  ${ShopBlocksCenteredContentFragment}
  ${ShopBlocksImageTextFragment}
  ${ShopBlocksCompareContentSliderFragment}
  ${ShopBlocksCompareTableFragment}
  ${ShopBlocksGenericIframeFragment}
  ${ShopBlocksImageOrVideoFragment}
  ${ShopBlocksNewsletterSignupFormFragment}
  ${RoutinePreviewFragment}
`;

export default {
  name: 'ShopBlocks',
  components: {
    GenericIframe,
    CompareContentSlider,
    ImageText,
    CenteredContent,
    FullWidthTeaserBox,
    ProductListingFeature,
    AsSeenIn,
    ImageTitleFeature,
    ProductSlider,
    LargeImageFeature,
    CollectionsSlider,
    ProductFeatureThree,
    SmallTopicFeature,
    AppCta,
    ProductGrid,
    ExerciseRoutineCardsSliderWithText,
    ContentRecommendationSlide,
    CollectionTitle,
    CollectionInformation,
    CompareTable,
    ImageOrVideo,
    NewsletterSignupForm,
  },
  mixins: [guestComponent],
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    gridBlocks: {
      type: Array,
      default: () => [],
    },
    collection: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
