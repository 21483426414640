<template>
  <div class="bg-blackly flex flex-col justify-end relative">
    <CraftAsset
      v-if="image[0]"
      class="h-full object-cover hidden md:block bg-white"
      :ratio="1.3"
      crop="fill"
      sizes="(min-width: 1440px) 405px, 30vw"
      :asset="image[0]"
      :alt="alt"
      autoplay
      muted
    />
    <CraftAsset
      v-if="image[0]"
      class="block object-cover h-1/2 md:hidden"
      :ratio="2 / 3"
      crop="fill"
      sizes="100vw"
      :asset="image[0]"
      :alt="alt"
      autoplay
      muted
    />
    <div class="relative md:absolute h-full md:h-auto w-full">
      <div class="box md:m-6 h-full flex flex-col justify-center">
        <BarText v-if="heading" tag="h2" class="mb-10" :text="heading" data-test-id="header" />
        <CtaArrow v-if="cta" v-bind="cta" class="cta-arrow font-medium" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { LinkFragment } from '@/components/common/LinkField'
import CtaArrow from '@/components/common/CtaArrow'
import CraftAsset, { CraftAssetFragment } from '@/components/common/CraftAsset'
import BarText from '@/components/BarText'

export const GridBlocksOneByOneImageCtaFragment = gql`
  fragment GridBlocksOneByOneImageCtaFragment on gridBlocks_oneByOneImageCta_BlockType {
    position
    typeHandle
    image {
      ...CraftAssetFragment
    }
    alt
    heading
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export default {
  name: 'OneByOneImageCta',
  components: { CtaArrow, CraftAsset, BarText },
  cols: 1,
  rows: 1,
  props: {
    position: {
      type: Number,
      default: null
    },
    image: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    cta: {
      type: Object,
      default: () => null
    }
  }
}
</script>
