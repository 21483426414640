<template>
  <LargeImageFeature v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import { LinkFragment } from '@/components/common/LinkField'
import LargeImageFeature from '@/components/common/blocks/LargeImageFeature'
import { CraftAssetFragment } from '@/components/common/CraftAsset'
export const ShopBlocksLargeImageFeatureFragment = gql`
  fragment ShopBlocksLargeImageFeatureFragment on shopBlocks_largeImageFeature_BlockType {
    typeHandle
    mainHeading
    heading
    body
    image {
      ...CraftAssetFragment
    }
    alt
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`

export default {
  components: {
    LargeImageFeature
  },
  props: {
    mainHeading: {
      type: String,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    },
    image: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: null
    },
    cta: {
      type: Object,
      default: () => null
    }
  }
}
</script>
