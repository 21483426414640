<template>
  <div class="compare-slider py-16" :class="{ 'bg-putty': backgroundGray }">
    <div class="container text-center">
      <h2 class="fluid-3xl leading-tightest max-w-xl mx-auto mb-10">{{ heading }}</h2>
      <div
        v-if="body"
        class="text-copy fluid-base leading-relaxed font-light max-w-3xl mx-auto break-words"
        data-testid="body"
        v-html="body"
      ></div>
    </div>
    <CardSlider :items="productList" :breakpoints="breakpoints" :slide-leveling="false">
      <ProductCompareCardSmall v-for="product in productList" :key="product.id" :item="product" data-test-id="item" />
    </CardSlider>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import CardSlider from '@/components/common/CardSlider'
import ProductCompareCardSmall from '@/components/shop/products/ProductCompareCardSmall'
import { LinkFragment } from '@/components/common/LinkField'

export const ShopBlocksCompareContentSliderFragment = gql`
  fragment ShopBlocksCompareContentSliderFragment on shopBlocks_compareContentSlider_BlockType {
    typeHandle
    heading
    body
    backgroundGray
    productList {
      ... on productList_BlockType {
        id
        primaryAttribute
        secondaryAttribute
        image {
          url
        }
        description
        cta {
          ...LinkFragment
        }
      }
    }
  }
  ${LinkFragment}
`
export default {
  name: 'CompareContentSlider',
  components: {
    CardSlider,
    ProductCompareCardSmall
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    backgroundGray: {
      type: Boolean,
      default: false
    },
    productList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1
        },
        {
          minWindowWidth: 720,
          slidesToShow: 2.5,
          slidesToScroll: 1
        },
        {
          minWindowWidth: 1024,
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      ]
    }
  }
}
</script>

<style>
.compare-slider .slider-arrows {
  @apply justify-center md:justify-end;
}
</style>
